import { Organization } from '@/models/organization';
import { db, auth, storage } from '@/firebase';
import { Converter } from '@/utils/converter';
import Logo from '@/models/logo';

export default {
  async getOrganizations(): Promise<Organization[] | null> {
    const querySnapshot = await db
      .collection('organizations')
      .withConverter(new Converter<Organization>())
      .get();

    const organizations = querySnapshot.docs.map(doc => doc.data());

    return organizations;
  },

  async getOrganizationsList(): Promise<Organization[] | null> {
    const querySnapshot = await db
      .collection('organizations')
      .withConverter(new Converter<Organization>())
      .get();

    const organizations = querySnapshot.docs.map(doc => doc.data());
    const token = await auth.currentUser?.getIdTokenResult();

    return (
      organizations.filter((org: Organization) => token?.claims.adminOf.includes(org.id)) || null
    );
  },

  async updateOrganization(org: Organization) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(org.id)
      .withConverter(new Converter<Organization>())
      .set(org, { merge: true });
  },

  async getOrgLogo(org: Organization): Promise<Logo> {
    const downloadURL = await storage.ref(org.organizationLogo.imageURL).getDownloadURL();

    return {
      imageURL: downloadURL,
      linkURL: org.organizationLogo.linkURL,
    };
  },

  async getSponsorLogos(org: Organization): Promise<Logo[]> {
    if (!org.sponsorLogos) {
      return [];
    }
    const logos = org.sponsorLogos.map(async logo => {
      const downloadURL = await storage.ref(logo.imageURL).getDownloadURL();

      const newLogo: Logo = {
        imageURL: downloadURL,
        linkURL: logo.linkURL,
      };

      return newLogo;
    });

    return Promise.all(logos);
  },
};
