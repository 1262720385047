


































































































































































































































































import { Organization, Series } from '@/models';
import { Division } from '@/models/division';
import LeaderboardResult from '@/models/leaderboardResult';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import OrganizationsService from '@/api/organization';
import Logo from '@/models/logo';
import RaceSnippet from '@/models/raceSnippet';

@Component({})
export default class Leaderboard extends Vue {
  search = '';
  expanded = [];
  headerName = 'Total Score';
  staticHeaders: { text: string; value: string; align?: string }[] = [
    { text: 'Rank', value: 'rank' },
    { text: 'First Name', value: 'firstName' },
    { text: 'Last Name', value: 'lastName' },
    { text: 'Club', value: 'club' },
    { text: 'Age Division', value: 'ageDivision' },
    { text: '', value: 'data-table-expand', align: 'mobile' },
  ];
  headers = this.staticHeaders;
  races: Map<string, string> = new Map();

  orgId = '';
  seriesId = '';
  selectedDivision = '';
  selectedSeries = '';
  filteredResults?: LeaderboardResult[];
  orgLogo: Logo | null = null;
  sponsorLogos: Logo[] = [];

  leaderboardHelpInfo = '';
  leaderboardHelpLinkUrl = '';
  leaderboardHelpLinkText = '';

  get divisions(): Division[] {
    return this.$store.state.leaderboards.divisions || [];
  }

  get seriesList(): Series[] {
    return this.$store.state.series.seriesList || [];
  }

  async created() {
    if (this.$store.state.organizations.organizations == null) {
      await this.$store.dispatch('organizations/getOrganizations');
    }

    this.orgId = this.$store.getters['organizations/getOrgIdFromSlug'](
      this.$route.params.organizationSlug
    );
    if (this.orgId) {
      const details: Organization = this.$store.getters['organizations/organizationDetails'](
        this.orgId
      );

      this.orgLogo = await OrganizationsService.getOrgLogo(details);
      this.sponsorLogos = await OrganizationsService.getSponsorLogos(details);

      this.leaderboardHelpInfo = details.leaderboardHelpInfo;
      this.leaderboardHelpLinkUrl = details.leaderboardHelpLinkUrl;
      this.leaderboardHelpLinkText = details.leaderboardHelpLinkText;

      if (this.$store.state.series.seriesList == null) {
        await this.$store.dispatch('series/getSeriesList', this.orgId);
      }

      await this.$store.dispatch('leaderboards/getDivisions', {
        orgId: this.orgId,
      });

      const series =
        this.seriesList.find(s => s.id === this.$route.query.series) || this.seriesList[0];
      this.selectedSeries = series.id;

      if (series.raceScoringMethod === 'Minimum Total Time') {
        this.headerName = 'Total Time';
      }

      this.selectedDivision =
        this.divisions.find(d => d.name === this.$route.query.division)?.name ||
        this.divisions[0].name;

      this.seriesId = this.selectedSeries;
      await this.$store.dispatch('leaderboards/getLeaderboards', {
        orgId: this.orgId,
        seriesId: this.seriesId,
      });

      if (this.selectedDivision !== '') {
        this.filteredResults = this.$store.getters['leaderboards/getLeaderboard'](
          this.selectedDivision
        )
          .filter((lb: LeaderboardResult) => lb.display)
          .sort((a: LeaderboardResult, b: LeaderboardResult) => a.rank - b.rank);

        this.fillRaces();
      }

      if (!this.$route.query.series && !this.$route.query.division) {
        await this.$router.replace({
          query: { series: this.selectedSeries, division: this.selectedDivision },
        });
      }
    }
  }

  async handleDivisionsChange() {
    if (this.selectedSeries !== '') {
      this.filteredResults = this.$store.getters['leaderboards/getLeaderboard'](
        this.selectedDivision
      )
        .filter((lb: LeaderboardResult) => lb.display)
        .sort((a: LeaderboardResult, b: LeaderboardResult) => a.rank - b.rank);

      this.fillRaces();

      await this.$router.replace({
        query: { series: this.selectedSeries, division: this.selectedDivision },
      });
    }
  }

  async handleSeriesChange() {
    this.seriesId = this.selectedSeries;
    await this.$store.dispatch('leaderboards/getLeaderboards', {
      orgId: this.orgId,
      seriesId: this.seriesId,
    });

    if (this.selectedDivision !== '') {
      this.filteredResults = this.$store.getters['leaderboards/getLeaderboard'](
        this.selectedDivision
      )
        .filter((lb: LeaderboardResult) => lb.display)
        .sort((a: LeaderboardResult, b: LeaderboardResult) => a.rank - b.rank);

      this.fillRaces();

      await this.$router.replace({
        query: { series: this.selectedSeries, division: this.selectedDivision },
      });
    }
  }

  fillRaces() {
    this.races = new Map();
    this.filteredResults?.forEach(r => {
      r.races.sort(
        (a: RaceSnippet, b: RaceSnippet) =>
          new Date(a.raceDate).getTime() - new Date(b.raceDate).getTime()
      );
      r.races.forEach(rr => {
        if (!this.races.has(rr.raceName)) {
          this.races.set(rr.raceName, rr.raceDate);
        }
      });
    });
    this.races = new Map(
      [...this.races.entries()].sort((a, b) => new Date(a[1]).getTime() - new Date(b[1]).getTime())
    );
    const dynHeaders = Array.from(this.races.keys()).map(race => ({ text: race, value: race }));
    const sortHeader = this.staticHeaders.slice(-1);
    this.headers = this.staticHeaders
      .slice(0, -1)
      .concat({ text: this.headerName, value: 'score' })
      .concat(dynHeaders)
      .concat(sortHeader);
  }

  prettyPrintFromSeconds(seconds: number): string {
    return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format('HH:mm:ss');
  }

  findRace(raceName: string, races: RaceSnippet[]): RaceSnippet | undefined {
    return races.find(r => r.raceName === raceName);
  }
}
